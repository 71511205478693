<template>
  <div class="index">
    <van-image width="100" height="100" :src="require('@/assets/imgs/成功.png')" />
    <div class="tip">领取成功</div>
    <div class="text">请在[微信]-[我的]-[卡包]中查看</div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.index {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}
.tip {
  font-size: 18px;
  margin: 30px 0 10px 0;
}
.text {
  font-size: 14px;
  color: #ccc;
}
</style>
